"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _state = _interopRequireDefault(require("@/libs/state"));
var _MediaViewer = _interopRequireDefault(require("./MediaViewer"));
var _MessageInfo = _interopRequireDefault(require("./MessageInfo"));
var methods = {
  props: {},
  displayNick: function displayNick() {
    var props = this.props;
    var suffix = props.message.nick ? ':' : '';
    return props.message.nick + suffix;
  },
  userMode: function userMode(user) {
    var props = this.props;
    return props.ml.buffer.userMode(user);
  },
  userModePrefix: function userModePrefix(user) {
    var props = this.props;
    return props.ml.buffer.userModePrefix(user);
  },
  themeColour: function themeColour(user) {
    var currentTheme = (0, _state.default)().setting('theme');
    var props = this.props;
    if (currentTheme === 'NewStyle' || currentTheme === 'Newstyle' || currentTheme === 'newstyle') {
      // eslint-disable-next-line no-nested-ternary
      var backgroundColor = props.ml.userColour(user) ? props.ml.userColour(user) : props.m().displayNick() ? 'black' : 'white';
      return {
        'background-color': backgroundColor
      };
    }
    return {
      color: props.ml.userColour(user)
    };
  }
};
var _default = {
  inject: {
    components: {
      default: {
        MessageInfo: _MessageInfo.default,
        MediaViewer: _MediaViewer.default
      }
    }
  },
  props: {
    ml: Object,
    message: Object,
    idx: Number,
    m: {
      default: function m() {
        // vue uses this function to generate the prop. `this`==null Return our own function
        return function n() {
          // Give our methods some props context before its function is called.
          // This is only safe because the function on the methods object is called on
          // the same js tick
          methods.props = this;
          return methods;
        };
      }
    }
  }
};
exports.default = _default;